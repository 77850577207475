import { v4 as uuidv4 } from "uuid";
// import bcryptjs from "bcryptjs";
import { ACD_DOMAIN, getClientId } from "./domains";

// const codeChallenge = bcryptjs.hashSync(codeVerifier, 10);
export const REFRESH_BEFORE = 2 * 60 * 1000;

export const origin = `${ACD_DOMAIN}/login`;

export async function getBody(shouldClear?: boolean) {
  let codeVerifier = localStorage.getItem("codeVerifier");
  if (!codeVerifier || shouldClear) {
    codeVerifier = uuidv4();
    localStorage.removeItem("codeVerifier");
    localStorage.setItem("codeVerifier", codeVerifier);
  }

  return {
    clientId: await getClientId(),
    redirectURI: `${ACD_DOMAIN}/auth/callback`,
    codeVerifier: codeVerifier,
    //The code verifier will be set outside.
  };
}
