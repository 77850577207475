/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import { authSliceActions } from "../../store/auth-slice";

import { OAUTH_DOMAIN, REFRESH_BEFORE, getClientId } from "../../constants";
import { RootState } from "../../store";
import { decodeToken } from "../../util";

export const NavBar = () => {
  const [collapse, setCollapse] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector((state: RootState) => state.auth.accessToken) || localStorage.getItem("accessToken");
  const refreshToken =
    useSelector((state: RootState) => state.auth.refreshToken) || localStorage.getItem("refreshToken");
  const refreshTokenExp =
    useSelector((state: RootState) => state.auth.refreshTokenExp) || localStorage.getItem("refreshTokenExp");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const refreshTokenFunc = useCallback(async () => {
    const url = OAUTH_DOMAIN + "/get-token";
    const data = {
      grantType: "refresh_token",
      clientId: await getClientId(),
      refreshToken: refreshToken,
    };
    try {
      // console.log("================== trying to refresh ==============");
      const response = await axios.post(url, data);
      const resData = response.data;

      if (resData.error) {
        console.error("Refresh Err", resData.error);
        dispatch(authSliceActions.logout());
        navigate("/login");
        return;
      }

      dispatch(
        authSliceActions.login({
          accessToken: resData.accessToken,
          refreshToken: resData.refreshToken,
          refreshTokenExp: Date.now() + resData.refreshTokenExpiresIn * 1000,
        })
      );
    } catch (err) {
      console.error("Refresh Err", err);
      dispatch(authSliceActions.logout());
      navigate("/login");
      return;
    }
  }, [dispatch, navigate, refreshToken]);

  useEffect(() => {
    setCollapse(false);
  }, [pathname]);

  useEffect(() => {
    if (accessToken && refreshToken) {
      // Check if refresh token has expired
      if (!refreshTokenExp || Date.now() > +refreshTokenExp) {
        dispatch(authSliceActions.logout());
        navigate("/login", { state: { tokenExpired: true } });
      }

      const decodedToken = decodeToken(accessToken)!;

      // Token has expired
      if (Date.now() > decodedToken.exp * 1000) {
        dispatch(authSliceActions.logout());
        navigate("/login");
        return;
      }

      // Refreshing done 2 mins before expiration
      const expiresIn = decodedToken.exp * 1000 - Date.now() - REFRESH_BEFORE;

      // If the token is to expire in the next 5 seconds , just refresh it immediately
      if (expiresIn <= 5000) {
        // console.log("========== Refreshing token immediately ===============");
        refreshTokenFunc();
        return;
      }

      // console.log(`============ Setting timeout to refresh token in ${expiresIn} ==============`);
      const timeout = setTimeout(() => {
        // console.log("========== Refreshing token in settimeout ============");
        refreshTokenFunc();
      }, expiresIn);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [accessToken, refreshToken, navigate, dispatch, refreshTokenFunc, refreshTokenExp]);

  return (
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 fixed-top ">
      <div className="container">
        <Link to="/" className="navbar-brand" id="berenText">
          TechRise Academy
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navmenu"
          onClick={() => setCollapse(!collapse)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${collapse ? "show" : ""}`} id="navmenu">
          <ul className="navbar-nav ms-auto text-light menu">
            <li className="nav-item">
              <NavLink className="nav-link" end to="/">
                Home
              </NavLink>
            </li>

            {/* <li className="nav-item">
              <NavLink className="nav-link" end to="/contact">
                Contact Us
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link" end to="/about">
                About us
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link" end to="/progress">
                Portal
              </NavLink>
            </li> */}
            {accessToken && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Portal
                </a>
                <ul className="dropdown-menu" style={{ backgroundColor: "#3a3b45" }} aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/progress">
                      Student
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/progress-admin">
                      Admin
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
            {/*  <li className="nav-item">
              <NavLink className="nav-link" end to="/project">
                Projects
              </NavLink>
            </li> */}
          </ul>
          {!accessToken && (
            <button
              className="btn btn-success me-4 m-1 "
              style={{ display: "block" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
          )}
          {accessToken && (
            <button
              className="btn btn-success me-4"
              onClick={() => {
                // localStorage.removeItem("accessToken");
                dispatch(authSliceActions.logout());
                navigate("/login");
              }}
            >
              Logout
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};
