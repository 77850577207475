import { berenCourses } from "../constants";
import { StoredTestimonial } from "../models";

export const dummyUser = {
  userName: "John Doe",
  imageUrl: "",
  details: {
    description: "An aspiring fullstack developer",
    curCourse: berenCourses.flutter.name,
    studentId: 2525,
    phoneNumber: 788990134,
    email: "johndoe@gmail.com",
    startDate: "28/07/2022",
    upComingCourses: ["Linux Course"],
    completedCourses: [
      berenCourses.javascript.name,
      berenCourses.typesript.name,
      berenCourses.nodeJS.name,
      berenCourses.sqlAndDatabases.name,
      berenCourses.react.name,
      berenCourses.reactNative.name,
    ],
    currentLectureTitle: " Handling user input",
    currentLectureNumber: 221,
  },
};

const jeromeTest =
  "TechRise Academy has made a highly competent software engineer out of the former rookie that was me. I did not have a clear path of what my journey would be in the IT industry. Then came the academy; it showed me a clear path and guided me step by step to the point where I now call myself a competent fullstack developer. The learning resources are rich in content and through this academy I have obtained insights that have pushed me to heights that I never imagined I would reach so quick in my career.";

const edwinTest =
  "Through TechRise Academy, I've managed to learn how to be a team player and be effective in problem solving and analysis. Through the daily scrum meetings, I've gained more confidence in communication and listening skills.Thanks for believing in me giving me a chance to enroll.";

const ianTest =
  "It's exciting to be in your final year of undergraduate and anticipating graduation,that was not the case for me .It was frightening thinking of how under skilled I will be fresh out of campus with theoretical computer science knowledge. Thus came in the academy, from a toddler in javascript ,now proficient and first choice.The details captured through the program makes me more comfortable and crispy than ever before with software development.I can now comfortably call myself a full stack developer - thanks to TechRise Academy.";

const denzelTest =
  "I wanted to make a career change, and TechRise Academy gave me the fundamentals and the confidence to make it happen. This program helped me to start a career that I love. I'm forever grateful to have got this opportunity, it has been a game changer";

const shalomTest =
  "I am deeply grateful for my experience at TechRise Academy. The well-designed curriculum, combining front-end and back-end development, equipped me with the skills and confidence to excel in tech. The supportive environment, daily scrums, and collaborative teamwork sharpened my problem-solving and logical thinking abilities. Thanks to TechRise Academy, I’ve built a solid coding foundation and joined an inspiring alumni network. I highly recommend this boot camp to anyone eager to launch or pivot their tech career!";

const shalineTest =
  "Before joining Techrise academy , I had little experience in web and backend development  but was eager to learn. The bootcamp provided a well-structured curriculum, hands-on projects, and incredible support from instructors and peers. One highlight was working on Work Hub project , which taught me a lot. By the end, I gained the confidence and skills. I highly recommend Tech Rise academy to anyone ready to take their career to the next level!";

const jerryTest =
  "Being part of Tech Rise was truly transformative. When I joined, I had zero knowledge as a developer. Today, I’m a confident full-stack developer, and it’s all thanks to their exceptional learning program. The curriculum goes beyond just teaching the basics,it also provides a deep understanding of programming languages and emphasizes the practical aspects of development. The hands on projects and mentorship allowed me to apply what I learned in real-world scenarios, making the transition from a beginner to a professional seamless. Tech Rise is not just a program; it’s a launchpad for anyone aspiring to thrive in tech.";

const cassimTest =
  "Participating in TechRise Academy was a transformative experience. The comprehensive curriculum and hands-on projects not only enhanced my technical skills but also boosted my confidence to tackle real-world challenges. The supportive community and expert mentors were instrumental in my journey, and I am now thriving in my tech career, thanks to TechRise.";

export const testimonialArr: StoredTestimonial[] = [
  {
    fName: "Edwin",
    lName: "Odhiambo",
    comment: edwinTest,
    imageUrl: "https://beren.fra1.cdn.digitaloceanspaces.com/assets/profile-pics/edwin.jpg",
  },
  {
    fName: "Jerome ",
    lName: "Jumah",
    comment: jeromeTest,
    imageUrl: "https://beren.fra1.cdn.digitaloceanspaces.com/assets/profile-pics/jerome.jpg",
  },
  {
    fName: "Denzel",
    lName: "Kipkemboi",
    comment: denzelTest,
    imageUrl: "https://beren.fra1.cdn.digitaloceanspaces.com/assets/profile-pics/denzel.jpg",
  },
  {
    fName: "Shalom",
    lName: "Wanjiku",
    comment: shalomTest,
    imageUrl: "https://beren.fra1.cdn.digitaloceanspaces.com/assets/profile-pics/shalom.png",
  },
  {
    fName: "Shaline",
    lName: "Wambui",
    comment: shalineTest,
    imageUrl: "https://beren.fra1.cdn.digitaloceanspaces.com/assets/profile-pics/shaline.jpg",
  },
  {
    fName: "Jerry",
    lName: "Rolience",
    comment: jerryTest,
    imageUrl: "https://beren.fra1.cdn.digitaloceanspaces.com/assets/profile-pics/jerry.jpg",
  },
  {
    fName: "Ian",
    lName: "Njoroge",
    comment: ianTest,
    imageUrl: "https://beren.fra1.cdn.digitaloceanspaces.com/assets/profile-pics/ian.jpg",
  },
  {
    fName: "Cassim",
    lName: "Hamisi",
    comment: cassimTest,
    imageUrl: "https://beren.fra1.cdn.digitaloceanspaces.com/assets/profile-pics/cassim.jpg",
  },
];
