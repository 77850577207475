import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { ScaleLoader } from "react-spinners";

interface PdfReaderProps {
  resumePath: string;
}

export const PdfReader = ({ resumePath }: PdfReaderProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@4.10.38/build/pdf.mjs">
        <Viewer
          renderLoader={(percentages: number) => (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <ScaleLoader color="#36d7b7" height={80} width={15} margin={5} radius={10} />
            </div>
          )}
          defaultScale={1.25}
          theme={"auto"}
          fileUrl={resumePath}
          plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
    </>
  );
};
