import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { json, redirect, useNavigate, useNavigation, useSearchParams } from "react-router-dom";

import { DashboardDesktop } from "../../components";
import { LoadingSpinner } from "../../components/common/loading-spinner";
import { ACD_DOMAIN } from "../../constants";
import { authSliceActions } from "../../store/auth-slice";
import { useGetStudentDataQuery } from "../../store/rtk/user-api";
import { updatesSliceActions } from "../../store/updates-slice";
import { userSliceActions } from "../../store/user-slice";

export const PersonalDashboard = () => {
  const editingState = useState(false);
  const { data, isLoading, error } = useGetStudentDataQuery(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();
  const testimonialParam = searchParams.get("testimonial");

  useEffect(() => {
    if (error) {
      dispatch(authSliceActions.logout());
      navigate("/login", { state: { err: true } });
    }

    if (data) {
      dispatch(userSliceActions.setUserDetails(data));
      // console.log("Data : ", data);
      if (testimonialParam && testimonialParam === "true") {
        dispatch(updatesSliceActions.showTestimonial());
      }
    }
  }, [data, dispatch, error, navigate, testimonialParam]);

  return (
    <>
      {(isLoading || navigation.state === "loading") && <LoadingSpinner />}
      <DashboardDesktop editingState={editingState} />
    </>
  );
};

export const personalDashboardLoader = async () => {
  try {
    const url = ACD_DOMAIN + "/get-role";
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return redirect("/login");
    }
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const resData = response.data;
    // console.log("Resdata : ", resData);
    if (resData.error) {
      throw resData.error;
    }

    const transFormedRoles = Array.isArray(resData) ? resData.map(r => r.role) : [];
    // console.log("transFormedRoles : ", transFormedRoles);

    // console.log("transFormedRoles.includes(student) :", transFormedRoles.includes("student"));
    if (!transFormedRoles.includes("student")) {
      throw json({ message: "Unauthorized access" }, { status: 401 });
    }

    return null;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
