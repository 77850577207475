import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ACD_DOMAIN } from "../../constants";
import {
  FetchedTestimonial,
  GetTrackerResp,
  UpdateData,
  UpdatePost,
  UpdateUserDetails,
  UserDetails,
} from "../../models";
import { RootState } from "../index";
import { transformErrorResponse } from "./error-handler";

export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["User"],
  baseQuery: fetchBaseQuery({
    baseUrl: ACD_DOMAIN,
    prepareHeaders(headers, { getState }) {
      headers.set("Content-Type", "application/json");
      const token = (getState() as RootState).auth.accessToken || localStorage.getItem("accessToken");
      if (token) headers.set("Authorization", `Bearer ${token}`);

      return headers;
    },
    timeout: 10000,
  }),

  endpoints: build => ({
    getTodaysUpdate: build.query<string, null>({
      query: body => ({ url: "/student/get-today-update", method: "GET" }),
      providesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
    getTestimonials: build.query<FetchedTestimonial[], null>({
      query: body => ({ url: "/testimonials", method: "GET" }),
      providesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
    getInitData: build.query<{ clientId: string; oauthUrl: string }, null>({
      query: body => ({ url: "/init-data", method: "GET" }),
      providesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
    getStudentData: build.query<UserDetails, null>({
      query: body => ({ url: "/student/data", method: "GET" }),
      providesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
    getUpdates: build.mutation<UpdateData[], { trackerId: number }>({
      query: body => ({ url: "/acd/get-updates", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
    getTrackers: build.mutation<GetTrackerResp[], { enrollementId: number }>({
      query: body => ({ url: "/acd/get-trackers", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
    postUpdate: build.mutation<string, UpdatePost>({
      query: body => ({ url: "/student/post-update", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
    postStudentDetailsUpdate: build.mutation<string, UpdateUserDetails>({
      query: body => ({ url: "/student/update-details", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
    postTestimonial: build.mutation<string, { comment: string; testimonyId?: string }>({
      query: body => ({ url: "/student/testimonial", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["User"],
      transformErrorResponse: transformErrorResponse,
    }),
  }),
});

export const { useGetTodaysUpdateQuery, useGetInitDataQuery, usePostUpdateMutation } = userApi;
export const { useGetTrackersMutation, useGetStudentDataQuery, usePostTestimonialMutation } = userApi;
export const { useGetUpdatesMutation, useGetTestimonialsQuery, usePostStudentDetailsUpdateMutation } = userApi;
