import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AdminApprovedEnrolleesTable,
  AdminPendingEnrolleesTable,
  AdminRejectedEnrolleesTable,
  NotificationModal,
} from "../../components";
import { EnrolleeData } from "../../models";
import { adminSliceActions } from "../../store/admin-slice";
import { useGetEnrolleesQuery } from "../../store/rtk/admin-api";

export const AdminEnrolleesTab = () => {
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [enrollees, setEnrollees] = useState<EnrolleeData[] | []>([]);
  const { data, isLoading, error } = useGetEnrolleesQuery(null);
  const dispatch = useDispatch();

  const enrolleesIsArr = Array.isArray(enrollees);

  useEffect(() => {
    if (data) {
      if (!Array.isArray(data)) {
        setServerError("Unable to fetch enrollees");
        setShowErrorBanner(true);
        return;
      } else {
        dispatch(adminSliceActions.setEnrollees(data));
        setEnrollees(data);
      }
    }
    if (error && !Array.isArray(data)) {
      setServerError("Unable to fetch enrollees");
      setShowErrorBanner(true);
      return;
    }
  }, [data, error, dispatch]);

  useEffect(() => {
    document.title = "Enrollees";
  }, []);

  const modalDataUseState = useState("");

  const pendingEnrollees = enrolleesIsArr ? enrollees.filter(enrollee => enrollee.status === "pending") : [];
  const approvedEnrollees = enrolleesIsArr ? enrollees.filter(enrollee => enrollee.status === "approved") : [];
  const rejectedEnrollees = enrolleesIsArr ? enrollees.filter(enrollee => enrollee.status === "rejected") : [];
  const alumniEnrollees = enrolleesIsArr ? enrollees.filter(enrollee => enrollee.status === "alumni") : [];

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      {/* {isLoading && <LoadingSpinner />} */}
      <div className="container mb-5 mt-2">
        <div className="row">
          <AdminPendingEnrolleesTable
            isLoading={isLoading}
            alumni={alumniEnrollees}
            pendingEnrollees={pendingEnrollees}
            setEnrollees={setEnrollees}
            modalDataUseState={modalDataUseState}
          />
        </div>
        <div className="row d-flex">
          <div className="col-lg-6 align-self-stretch">
            <AdminApprovedEnrolleesTable
              approvedEnrollees={approvedEnrollees}
              modalDataUseState={modalDataUseState}
              isLoading={isLoading}
            />
          </div>
          <div className="col-lg-6 align-self-stretch">
            <AdminRejectedEnrolleesTable
              rejectedEnrollees={rejectedEnrollees}
              modalDataUseState={modalDataUseState}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
