import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { ChangeEvent, FormEvent, useEffect, useLayoutEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import PhoneInput, { CountryData } from "react-phone-input-2";

import "filepond/dist/filepond.min.css";
import "react-phone-input-2/lib/bootstrap.css";
import { DOC_DOMAIN, getClientId } from "../../constants";
import { ErrorType } from "../../models";
import { useEnrollPublicUserMutation } from "../../store/rtk/enrollment-api";
import { LoadingSpinner } from "../common/loading-spinner";
import { NotificationModal } from "../common/notification-modal";

// Register the plugins
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const initialState = {
  inputData: "",
  isValid: false,
  isTouched: false,
  error: "",
};

const initialFileState = {
  fileDataPath: "",
  isTouched: false,
  isUploaded: false,
  isValid: false,
  isReverted: false,
  error: "",
};

interface ErrorModalProps {
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEnrollmentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setServerData: React.Dispatch<React.SetStateAction<string | null>>;
  showEnrollmentModal: boolean;
}

const phoneNoRegex = /^\+?\d{1,3}[-.\s]?\d{1,14}$/;
const isLessThan3CharsLong = (value: string) => value.length <= 2;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// const kenyanPhoneNumberRegex = /^254\d{9}$/;

export const EnrollmentModal = (props: ErrorModalProps) => {
  const { setServerData, setShowEnrollmentModal, showEnrollmentModal } = props;
  const [firstName, setFirstNameInput] = useState(initialState);
  const [lastName, setLastNameInput] = useState(initialState);
  const [email, setEmailInput] = useState(initialState);
  const [phoneNo, setPhoneNoInput] = useState(initialState);
  const [resumeFile, setResumeFile] = useState(initialFileState);
  const [errorMesg, setErrorMesg] = useState<null | string>(null);
  const [showErrorBanner, setShowErrorBanner] = useState(true);
  const [clientId, setClientId] = useState<string | null>(null);

  const [enrollUser, { isLoading, error }] = useEnrollPublicUserMutation();

  useLayoutEffect(() => {
    (async () => {
      const id = await getClientId();
      setClientId(id);
    })();
  }, []);

  useEffect(() => {
    if (error) {
      console.log("Error from rtk in useEffect :", error);
      setErrorMesg(`${error}`);
      setShowErrorBanner(true);
    }
  }, [error]);

  useEffect(() => {
    // This sets overflow to hidden on the body hence allowing the modal's scrollbars to activate
    if (showEnrollmentModal) document.body.classList.add("modal-open");
    else document.body.classList.remove("modal-open");

    // Clean up when the modal is closed
    return () => document.body.classList.remove("modal-open");
  }, [showEnrollmentModal]);

  const firstNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimedInput = e.target.value.trim();
    if (isLessThan3CharsLong(trimedInput)) {
      setFirstNameInput({
        inputData: trimedInput,
        isValid: false,
        isTouched: true,
        error: "first name should be at least 3 characters long",
      });
    } else {
      setFirstNameInput({
        inputData: trimedInput,
        isValid: true,
        isTouched: true,
        error: "",
      });
    }
  };

  const lastNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimedInput = e.target.value;
    if (isLessThan3CharsLong(trimedInput)) {
      setLastNameInput({
        inputData: trimedInput,
        isValid: false,
        isTouched: true,
        error: "last name should be at least 3 characters long",
      });
    } else {
      setLastNameInput({
        inputData: trimedInput,
        isValid: true,
        isTouched: true,
        error: "",
      });
    }
  };

  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimmedInput = e.target.value.trim();
    if (emailRegex.test(trimmedInput)) {
      setEmailInput({
        inputData: trimmedInput,
        isValid: true,
        isTouched: true,
        error: "",
      });
    } else {
      setEmailInput({
        inputData: trimmedInput,
        isValid: false,
        isTouched: true,
        error: "invalid email",
      });
    }
  };

  const phoneChangeHandler = (phone: string, countryData: CountryData) => {
    const trimmedInput = phone.trim();
    if (phoneNoRegex.test(trimmedInput)) {
      setPhoneNoInput({
        inputData: trimmedInput,
        isValid: true,
        isTouched: true,
        error: "",
      });
    } else {
      setPhoneNoInput({
        inputData: trimmedInput,
        isValid: false,
        isTouched: true,
        error: "Invalid phone number",
      });
    }
  };

  function hideModal() {
    setShowEnrollmentModal(false);
  }

  const submitEventHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const data = {
      fName: formData.get("firstName") as string,
      lName: formData.get("LastName") as string,
      email: formData.get("email") as string,
      phoneNo: formData.get("phoneNumber") as string,
      resumeUrl: formData.get("resumeFilePath") as string,
    };

    const sendRegDataToServer = async () => {
      try {
        const serverRes = (await enrollUser(data)) as { data: string; error: ErrorType };
        if (serverRes.error) {
          setErrorMesg(serverRes.error.data.error.message);
          setShowErrorBanner(true);
          return;
        }

        hideModal();
        setServerData(serverRes.data);
      } catch (err) {
        setErrorMesg("Something went wrong");
        setShowErrorBanner(true);
      }
    };

    sendRegDataToServer();
  };

  // Class selectors for the error states of each input
  const firstNameClasses =
    firstName.isValid === false && firstName.isTouched === true ? "form-control  is-invalid" : "form-control";
  const lastNameClasses =
    lastName.isValid === false && lastName.isTouched === true ? "form-control  is-invalid" : "form-control";
  const emailClasses =
    email.isValid === false && email.isTouched === true ? "form-control  is-invalid" : "form-control";
  const phoneNoClasses = phoneNo.isValid === false && phoneNo.isTouched === true ? "is-invalid" : "";
  const fileInputClasses =
    resumeFile.isValid === false && resumeFile.isTouched === true && resumeFile.error !== ""
      ? "App mb-1 form-control  is-invalid"
      : "App mb-1 form-control";

  const formIsValid =
    firstName.isValid &&
    lastName.isValid &&
    email.isValid &&
    phoneNo.isValid &&
    resumeFile.isValid &&
    resumeFile.fileDataPath &&
    resumeFile.isUploaded &&
    !resumeFile.isReverted;

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {errorMesg && showErrorBanner && (
        <NotificationModal
          onClose={() => setShowErrorBanner(false)}
          // message="Failed to submit form. Please try again."
          message={errorMesg}
          show={true}
          success={false}
        />
      )}

      <div
        className="modal fade show "
        role={"dialog"}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.2)" }}
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered my-0">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="enrollLabel">
                Enrollment Application
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  hideModal();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <p className="lead">Fill out this form and we will get back to you</p>
              <form method="post" onSubmit={submitEventHandler}>
                <div className="mb-2">
                  <label htmlFor="firstName" className="col-form-label">
                    First Name:
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className={firstNameClasses}
                    id="firstName"
                    onChange={firstNameChangeHandler}
                    aria-describedby="firstNameFeedback"
                    value={firstName.inputData}
                    required
                  />
                  {firstName.error && (
                    <div id="firstNameFeedback" className="invalid-feedback">
                      {firstName.error}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <label htmlFor="LastName" className="col-form-label">
                    Last Name:
                  </label>
                  <input
                    type="text"
                    name="LastName"
                    className={lastNameClasses}
                    id="LastName"
                    onChange={lastNameChangeHandler}
                    aria-describedby="lastNameFeedback"
                    value={lastName.inputData}
                    required
                  />
                  {lastName.error && (
                    <div id="lastNameFeedback" className="invalid-feedback">
                      {lastName.error}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <label htmlFor="email" className="col-form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={emailClasses}
                    id="email"
                    onChange={emailChangeHandler}
                    aria-describedby="emailFeedback"
                    value={email.inputData}
                    required
                  />
                  {email.error && (
                    <div id="emailFeedback" className="invalid-feedback">
                      {email.error}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <label htmlFor="">Phone Number</label>
                  <PhoneInput
                    containerClass={phoneNoClasses}
                    inputClass="phoneNoInput"
                    inputProps={{
                      name: "phoneNumber",
                      // value: `${phoneNo.inputData}`,
                      required: true,
                      autoFocus: false,
                    }}
                    country={"ke"}
                    preferredCountries={["ke", "us"]}
                    enableSearch={true}
                    value={phoneNo.inputData}
                    onChange={(phone: string, countryData: CountryData) => {
                      phoneChangeHandler(phone, countryData as CountryData);
                    }}
                  />

                  {phoneNo.error && (
                    <div id="phoneNoFeedback" className="invalid-feedback">
                      {phoneNo.error}
                    </div>
                  )}
                </div>
                {/* ========================File input========================== */}

                <div className={fileInputClasses} style={{ maxHeight: "5.5rem" }}>
                  <FilePond
                    id={"fileInput"}
                    allowMultiple={true}
                    credits={false}
                    allowFileSizeValidation={true}
                    maxFileSize={"5MB"}
                    labelMaxFileSizeExceeded={"File is too large"}
                    maxFiles={1}
                    checkValidity={true}
                    acceptedFileTypes={["application/pdf"]}
                    beforeAddFile={item => {
                      if (item.fileType !== "application/pdf") {
                        setResumeFile({
                          fileDataPath: "",
                          isTouched: true,
                          isValid: false,
                          isReverted: false,
                          isUploaded: false,
                          error: "File extension should be .pdf",
                        });
                        return false;
                      }
                      return true;
                    }}
                    name="upload"
                    server={{
                      url: `${DOC_DOMAIN}`,
                      process: { url: "/s3/pub-upload", method: "POST", headers: { "Client-Auth": clientId || "" } },
                      revert: {
                        url: "/s3/pub-delete",
                        method: "DELETE",
                        headers: { "Content-Type": "application/json", "Client-Auth": clientId || "" },
                      },
                    }}
                    onprocessfilerevert={() => {
                      setResumeFile({
                        fileDataPath: resumeFile && resumeFile.fileDataPath,
                        isTouched: true,
                        isValid: true,
                        isUploaded: true,
                        isReverted: true,
                        error: "",
                      });
                    }}
                    onprocessfile={(error, file) => {
                      // Data sent from the server : file.serverId
                      if (!error) {
                        const serverResponse: {
                          url: string;
                          msg: string;
                        } = JSON.parse(file.serverId);

                        if (!error) {
                          const filePathDigitalOcean = serverResponse.url;
                          // console.log("filePathDigitalOcean: ", filePathDigitalOcean);
                          // console.log("serverResponse: ", serverResponse);

                          //  Send upload state and imageUrl to parent component
                          setResumeFile({
                            fileDataPath: filePathDigitalOcean,
                            isTouched: true,
                            isValid: true,
                            isUploaded: true,
                            isReverted: false,
                            error: "",
                          });
                        } else {
                          setResumeFile({
                            fileDataPath: "",
                            isTouched: true,
                            isValid: false,
                            isUploaded: false,
                            isReverted: false,
                            error: "",
                          });
                        }
                      }
                    }}
                    labelIdle='<span class="filepond--label-action"> Upload Resume (pdf only)</span>'
                  />
                  {resumeFile.error && (
                    <label
                      className="row mt-0 pt-0 mb-3 "
                      style={{
                        display: "block",
                        color: "#dc3545",
                        fontSize: "0.875em",
                        marginTop: "0.25rem",
                      }}
                    >
                      {resumeFile.error}
                    </label>
                  )}

                  {/* Hidden input to hold the file path */}
                  <input name="resumeFilePath" type="text" hidden={true} defaultValue={resumeFile.fileDataPath} />
                </div>

                <div className="modal-footer pt-3">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-default btn-success"
                    data-bs-dismiss={formIsValid ? "modal" : ""}
                    disabled={!formIsValid}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
