import { LogTypes } from "@kberen/pkg.shared-types";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ACD_DOMAIN, getClientId } from "../constants";

export function sendLogBody(message: string, desc?: string): { logs: LogTypes.LogData[] } {
  const body: { logs: LogTypes.LogData[] } = {
    logs: [{ logId: uuidv4(), level: "debug", sentAt: new Date(), message, desc }],
  };

  return body;
}

export function sendLog(message: string) {
  const logUrl = ACD_DOMAIN + "/log";
  const data = sendLogBody(message);
  async function sendLogAsync() {
    // 1. Get client id
    const clientId = await getClientId();
    // 2. Send error to logger
    try {
      const headers = { "Content-Type": "application/json", "Client-Auth": clientId };
      await axios.post(logUrl, data, { headers });
    } catch (err) {
      console.error(err);
    }
  }
  sendLogAsync();
}
