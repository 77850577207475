import { AnyAction } from "@reduxjs/toolkit";
import axios from "axios";
import { Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";

import { OAUTH_DOMAIN, getClientId } from "../constants";

export const refreshTokenFunc = async (
  refreshToken: string,
  authSliceActions: any,
  dispatch: Dispatch<AnyAction>,
  navigate: NavigateFunction,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const url = OAUTH_DOMAIN + "/get-token";
  const data = {
    grantType: "refresh_token",
    clientId: await getClientId(),
    refreshToken: refreshToken,
  };
  try {
    setIsLoading(true);
    const response = await axios.post(url, data);
    const resData = response.data;

    if (resData.error) {
      setIsLoading(false);
      console.error("Refresh Err", resData.error);
      dispatch(authSliceActions.logout());
      navigate("/login");
      return;
    }

    dispatch(
      authSliceActions.login({
        accessToken: resData.accessToken,
        refreshToken: resData.refreshToken,
        refreshTokenExp: Date.now() + resData.refreshTokenExpiresIn * 1000,
      })
    );
    setIsLoading(false);
  } catch (err) {
    setIsLoading(false);
    //   console.error("Refresh Err", err);
    dispatch(authSliceActions.logout());
    navigate("/login");
    return;
  }
};
