import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import RestoreIcon from "@mui/icons-material/Restore";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import { Avatar, Box, Button, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import { ClockLoader } from "react-spinners";
import { ReactComponent as PersonSvg } from "../../assets/svg/person-4.svg";
import * as Components from "../../components";
import { AssignCourseModal } from "../../components/admin-dashboard/assign-course-modal";
import { LoadingSpinnerForTable } from "../../components/common/loading-spinner";
import { ActiveStudents, ErrorType, RemCourse } from "../../models";
import * as adminApi from "../../store/rtk/admin-api";
import { getWeekNumber } from "../../util";

export const AdminMembersTab = () => {
  const [attendanceScore, setAttendanceScore] = useState<string | null>(null);
  const [members, setMembers] = useState<ActiveStudents[] | []>([]);
  const [selectedMember, setSelectedMember] = useState<ActiveStudents | null>(null);
  const [showViewAttendance, setShowViewAttendance] = useState(false);
  const [showSetAttendance, setShowSetAttendance] = useState(false);
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  const [showAttendanceScoreModal, setShowAttendanceScoreModal] = useState(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [showAssignCourseModal, setShowAssignCourseModal] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [confirmationPromptMessg, setConfirmationPromptMessg] = useState("");
  const [getPendingCourses] = adminApi.useGetPendingCoursesMutation();
  const [pendingCoursesAreEmpty, setPendingCoursesAreEmpty] = useState(false);
  const [action, setAction] = useState<"makeAlumni" | "unassignCourse" | null>(null);
  const [searchParams] = useSearchParams();
  const itemsPerPage = 5;
  const studentId = searchParams.get("id");
  const noMembers = members.length === 0;
  const disableMakeAlumniBtn = noMembers || !pendingCoursesAreEmpty;

  const { data, isLoading, error } = adminApi.useGetActiveStudentsQuery(null);
  const [getAttendanceScore, { isLoading: isGettingAttendanceScore, error: attendanceScoreError }] =
    adminApi.useGetAttendanceScoreMutation();
  const [addAlumni, { isLoading: isAddingAlumni, error: addingAlumniError }] = adminApi.useAddAlumniMutation();
  const [deleteTracker, { isLoading: isDeletingTracker }] = adminApi.useDeleteTrackerMutation();

  const fetchPendingCourses = useCallback(
    async (enrolleeId: number) => {
      const serverRes = (await getPendingCourses({ enrollmentId: enrolleeId })) as {
        data: RemCourse[];
        error: ErrorType;
      };
      if (serverRes.error) {
        setPendingCoursesAreEmpty(true);
        return;
      }

      if (serverRes.data.length === 0) {
        setPendingCoursesAreEmpty(true);
      } else {
        setPendingCoursesAreEmpty(false);
      }
    },
    [getPendingCourses]
  );

  useEffect(() => {
    if (error) {
      setServerError(`Unable to fetch active members`);
      setShowErrorBanner(true);
    }
    if (attendanceScoreError) {
      setServerError(`Unable to fetch attendance score`);
      setShowErrorBanner(true);
    }

    if (addingAlumniError && selectedMember?.fName) {
      setServerError(`Unable to make ${selectedMember?.fName} an alumni`);
      setShowErrorBanner(true);
    }
  }, [error, attendanceScoreError, addingAlumniError, selectedMember]);

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      if (studentId) {
        const memberWithId = data.find(member => member.id === +studentId);
        if (!memberWithId) {
          setServerError(`No member with that id found`);
          setShowErrorBanner(true);
          setMembers(data);
          fetchPendingCourses(data[0].id);
          setSelectedMember(data[0]);
          return;
        }
        // console.log("Students : ", data);
        setSelectedMember(memberWithId!);
        const updatedMembers = [memberWithId, ...data.filter(memb => memb.id !== memberWithId.id)];
        setMembers(updatedMembers);
      } else {
        setMembers(data);
        fetchPendingCourses(data[0].id);
        setSelectedMember(data[0]);
      }
    }
  }, [data, studentId, fetchPendingCourses]);

  useEffect(() => {
    if (selectedMember) {
      const sendDataToServer = async () => {
        const today = new Date();
        try {
          const data = { enrollmentId: selectedMember!.id, week: getWeekNumber(today), year: today.getUTCFullYear() };
          const serverRes = (await getAttendanceScore(data)) as { data: number; error: ErrorType };

          if (serverRes.error) {
            setServerError(serverRes.error.data.error.message);
            setShowErrorBanner(true);
            setAttendanceScore(`???`);
          }

          setAttendanceScore(`${serverRes.data.toFixed(2)}%`);
        } catch (err) {
          setServerError(`Unable to fetch attendance score`);
          setShowErrorBanner(true);
          setAttendanceScore(`???`);
        }
      };

      sendDataToServer();
    }
  }, [selectedMember, getAttendanceScore]);

  useEffect(() => {
    document.title = "Members";
  }, []);

  const makeAlumni = () => {
    const sendDataToServer = async () => {
      try {
        const data = { enrollmentId: selectedMember!.id };
        const serverRes = (await addAlumni(data)) as { data: string; error: ErrorType };

        if (serverRes.error) {
          setServerError(serverRes.error.data.error.message);
          setShowErrorBanner(true);
        }
      } catch (err) {
        setServerError(`Unable to fetch make ${selectedMember?.fName} an alumni `);
        setShowErrorBanner(true);
      }
    };

    sendDataToServer();
  };

  const unassignCourse = () => {
    const sendDataToServer = async () => {
      try {
        const data = { trackerId: selectedMember!.trackerId! };
        const serverRes = (await deleteTracker(data)) as { data: string; error: ErrorType };

        if (serverRes.error) {
          setServerError(serverRes.error.data.error.message);
          setShowErrorBanner(true);
        }
      } catch (err) {
        setServerError(`Unable to fetch make ${selectedMember?.fName} an alumni `);
        setShowErrorBanner(true);
      }
    };

    sendDataToServer();
  };

  function MembersRows({ members }: { members: ActiveStudents[] }) {
    return (
      <>
        {members &&
          members!.map((member, index) => (
            <tr
              key={member.id}
              style={{
                cursor: "pointer",
                backgroundColor: member.id === selectedMember?.id ? "#EAECF4" : "transparent",
              }}
              onClick={() => {
                setSelectedMember(member);
                fetchPendingCourses(member.id);
              }}
            >
              <td className="py-3">{members.findIndex(user => user === member) + 1}</td>
              <td className="py-3">
                {member.fName} {member.lName}
              </td>
              <td className="py-3">
                {member.comment && member.curLecNo ? `${member.curLecNo}. ${member.comment}` : "???"}
              </td>
              <td className="py-3">{member.curCourse ? member.curCourse : "???"}</td>
              <td className="py-3">
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: member.progress ? `${member.progress}%` : "0%" }}
                    aria-valuenow={member.progress ? +member.progress : 0}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {member.progress ? `${member.progress}%` : "0%"}
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentMembers = members.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(members.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % members.length;
    setItemOffset(newOffset);
  };

  const actionIsMakeAlumni = action === "makeAlumni";

  return (
    <>
      {serverError && showErrorBanner && (
        <Components.NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <div className="activeMembersCard container-fluid mt-5">
        <div className="row">
          <div className="col-lg-8 grid-margin stretch-card tableCard">
            <div className="card">
              <div className="card-header">
                <h4>Active Member Attendance</h4>
              </div>
              <div className="card-body shadow">
                <div className="table-responsive" style={{ minHeight: "20rem" }}>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Current Lecture</th>
                        <th>Current Course</th>
                        <th>Progress</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && <LoadingSpinnerForTable />}
                      <MembersRows members={currentMembers} />
                      {!isLoading && noMembers && (
                        <tr
                          style={{
                            backgroundColor: "#fff",
                            height: "100%",
                            border: "none",
                            borderColor: "transparent",
                            fontSize: "1.2rem",
                          }}
                        >
                          <td colSpan={100} style={{ textAlign: "center", backgroundColor: "#fff" }}>
                            No members found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 grid-margin stretch-card">
            <div className="card shadow text-center mb-3">
              <div className="p-4 text-center container" style={{ color: "#858796" }}>
                <div className="row align-items-center">
                  <div className="col-md-5" style={{ paddingRight: 0 }}>
                    {selectedMember?.imageUrl ? (
                      <Avatar
                        alt={selectedMember.fName}
                        src={selectedMember.imageUrl}
                        sx={{ width: "7rem", height: "7rem" }}
                      />
                    ) : (
                      <PersonSvg
                        style={{
                          fill: "#858796",
                          width: "7rem",
                          borderWidth: "2px",
                          border: "1px solid ",
                          borderRadius: "50%",
                          paddingRight: 0,
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md" style={{ paddingLeft: 0 }}>
                    <h5 className="card-title">
                      {selectedMember?.fName} {selectedMember?.lName}
                    </h5>
                    <Box sx={{ px: 1 }}>
                      <Button
                        sx={{ textTransform: "inherit", borderRadius: "1rem", px: 2, maxHeight: "1.75rem", mb: 1 }}
                        variant="contained"
                        color="success"
                        fullWidth
                        disabled={disableMakeAlumniBtn || !selectedMember?.trackerId}
                        size="small"
                        startIcon={<SchoolRoundedIcon />}
                        onClick={() => {
                          setAction("makeAlumni");
                          setConfirmationPromptMessg(
                            `${selectedMember?.fName} ${selectedMember?.lName} will become an alumni`
                          );
                          setShowConfirmationPrompt(true);
                        }}
                      >
                        Make alumni
                      </Button>

                      <Button
                        sx={{ textTransform: "inherit", borderRadius: "1rem", px: 2, maxHeight: "1.75rem" }}
                        variant="contained"
                        color="info"
                        fullWidth
                        disabled={Boolean(noMembers || (selectedMember && selectedMember.completedTracker === false))}
                        size="small"
                        startIcon={<CastForEducationIcon />}
                        onClick={() => {
                          setShowAssignCourseModal(true);
                        }}
                      >
                        Assign Course
                      </Button>

                      <Button
                        sx={{ textTransform: "inherit", borderRadius: "1rem", maxHeight: "1.75rem", mt: 1 }}
                        variant="contained"
                        color="error"
                        fullWidth
                        size="small"
                        disabled={Boolean(
                          noMembers ||
                            (selectedMember &&
                              (!selectedMember.trackerId ||
                                (selectedMember.trackerId && selectedMember.updatesCount !== 0)))
                        )}
                        startIcon={<RestoreIcon />}
                        onClick={() => {
                          setAction("unassignCourse");
                          setConfirmationPromptMessg(
                            `You'll be unassigning the course : ${selectedMember?.curCourse} from ${selectedMember?.fName} ${selectedMember?.lName}`
                          );
                          setShowConfirmationPrompt(true);
                        }}
                      >
                        Unassign course
                      </Button>
                    </Box>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <Grid container>
                      <Grid item xs={2}>
                        <i className="fas fa-bolt"></i>
                      </Grid>
                      <Grid item xs={7}>
                        <p>Pace of coverage</p>
                      </Grid>
                      <Grid item xs={3}>
                        {selectedMember?.pace ? `${selectedMember.pace} lec/day` : ` ??? `}
                      </Grid>
                    </Grid>
                  </li>

                  <li className="list-group-item">
                    <Grid container>
                      <Grid item xs={2}>
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          style={{ padding: "0px 4px" }}
                          disabled={noMembers}
                          onClick={() => {
                            setShowAttendanceScoreModal(true);
                          }}
                        >
                          <i className="far fa-calendar-check"></i>
                        </button>
                      </Grid>
                      <Grid item xs={7}>
                        <p>Attendance score</p>
                      </Grid>
                      <Grid item xs={3}>
                        {isGettingAttendanceScore && (
                          <div style={{ textAlign: "end" }}>
                            <ClockLoader color="#36d7b7" size={25} />
                          </div>
                        )}
                        {attendanceScore}
                      </Grid>
                    </Grid>
                  </li>

                  <li className="list-group-item">
                    <Grid container>
                      <Grid item xs={12}>
                        {/* <div className="row"> */}
                        <div className="accordion accordion-flush p-0" id="accordionFlushExample">
                          <div className="accordion-item">
                            <h2 id="flush-headingOne">
                              <button
                                className="accordion-button collapsed p-1"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                                style={{ color: "#212529" }}
                              >
                                <p style={{ width: "22%" }}>
                                  <i className="px-2 fas fa-history"></i>
                                </p>

                                <p style={{ flex: "1" }}>Recent update</p>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                {selectedMember?.comment
                                  ? `${selectedMember.curLecNo}. ${selectedMember.comment}`
                                  : ` ??? `}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      {/* </div> */}
                    </Grid>
                  </li>
                </ul>
                <div className="container card-footer ">
                  <div className="row pt-3">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        disabled={noMembers}
                        onClick={() => {
                          setShowViewAttendance(true);
                        }}
                      >
                        View Attendance
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        disabled={noMembers}
                        onClick={() => {
                          setShowSetAttendance(true);
                        }}
                      >
                        Set Excused Dates
                      </button>
                    </div>
                    {/* Modals for setting expected dates and viewing attendance dates  */}
                    {showViewAttendance && (
                      <Components.ViewAttendanceCalender
                        selectedMember={selectedMember}
                        show={showViewAttendance}
                        setShow={setShowViewAttendance}
                      />
                    )}
                    {showSetAttendance && (
                      <Components.SetExpectedDatesCalender
                        selectedMember={selectedMember}
                        show={showSetAttendance}
                        setShow={setShowSetAttendance}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmationPrompt && (
        <Components.ConfirmationPrompt
          status={actionIsMakeAlumni ? "success" : "danger"}
          message={confirmationPromptMessg}
          setShowConfirmationPrompt={setShowConfirmationPrompt}
          confirmationAction={actionIsMakeAlumni ? makeAlumni : unassignCourse}
          isLoading={actionIsMakeAlumni ? isAddingAlumni : isDeletingTracker}
        />
      )}

      {showAttendanceScoreModal && (
        <Components.AttendanceScoreCarlenderModal
          selectedMember={selectedMember}
          setShow={setShowAttendanceScoreModal}
          show={showAttendanceScoreModal}
        />
      )}
      {showAssignCourseModal && (
        <AssignCourseModal
          selectedEnrollee={selectedMember}
          hideModal={() => {
            setShowAssignCourseModal(false);
          }}
        />
      )}
    </>
  );
};
