import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { ActionFunction, json, redirect } from "react-router-dom";
import { AddCourseModal, ConfirmationPrompt, EditCourseModal, NotificationModal } from "../../components";
import { LoadingSpinnerForTable } from "../../components/common/loading-spinner";
import { ACD_DOMAIN } from "../../constants";
import { ErrorType, FetchedCourse } from "../../models";
import { adminSliceActions } from "../../store/admin-slice";
import { useDeleteCourseMutation, useGetCoursesQuery } from "../../store/rtk/admin-api";

export const AdminCoursesTab = () => {
  const [itemOffset, setItemOffset] = useState(0);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  const [showCreateCourseModal, setShowCreateCourseModal] = useState(false);

  const { data, isLoading, error: fetchingCoursesError } = useGetCoursesQuery(null);
  const [deleteCourse, { isLoading: isDeleting, error: deletionError }] = useDeleteCourseMutation();
  const [fetchedCourses, setFetchedCourses] = useState<FetchedCourse[] | []>([]);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [selectedCourse, setSelectedCourse] = useState<FetchedCourse>();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Courses";
  }, []);

  useEffect(() => {
    if (data) {
      setFetchedCourses(data);
      dispatch(adminSliceActions.setCourses(data));
    }

    if (fetchingCoursesError) {
      setServerError(`${fetchingCoursesError}`);
      setShowErrorBanner(true);
    }
  }, [data, deletionError, selectedCourse?.name, fetchingCoursesError, dispatch]);
  const itemsPerPage = 5;

  const deleteCourseFn = async (courseId: number) => {
    const sendDataToServer = async () => {
      try {
        const serverRes = (await deleteCourse({ courseId: courseId })) as { data: string; error: ErrorType };
        if (serverRes.error) {
          setServerError(serverRes.error.data.error.message);
          setShowErrorBanner(true);

          return;
        }
      } catch (err) {
        setServerError(`Unable to delete ${selectedCourse?.name}. Please try again`);
        setShowErrorBanner(true);
      }
    };

    sendDataToServer();
  };

  function CourseRow({ courses }: { courses: FetchedCourse[] }) {
    return (
      <>
        {courses &&
          courses.map((courseObj, index) => (
            <tr key={courseObj.id}>
              <td className="py-1">{fetchedCourses.findIndex(course => course === courseObj) + 1}</td>
              <td>{courseObj.name}</td>
              <td>{courseObj.totalLecNo}</td>
              <td>{courseObj.author}</td>
              <td>{courseObj.completionDays}</td>
              <td>{courseObj.pace || "?"}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-warning pt-1 pb-1"
                  onClick={() => {
                    setShowEditCourseModal(true);
                    setSelectedCourse(courseObj);
                  }}
                >
                  <i className="fas fa-edit"></i>
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger pt-1 pb-1"
                  onClick={() => {
                    setShowConfirmationPrompt(true);
                    setSelectedCourse(courseObj);
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentCourses = fetchedCourses.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(fetchedCourses.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % fetchedCourses.length;
    setItemOffset(newOffset);
  };

  let deleteMessage = "";
  if (selectedCourse) {
    deleteMessage = `${selectedCourse.name} will be deleted `;
  }

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}

      <div className="container-fluid mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <h4>All Courses</h4>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      setShowCreateCourseModal(true);
                    }}
                  >
                    Add new course
                  </button>
                </div>
              </div>
              <div className="card-body shadow">
                <div className="table-responsive" style={{ minHeight: "15rem" }}>
                  <table className="table  table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Course Name</th>
                        <th>Lecture Number</th>
                        <th>Author</th>
                        <th>Completion Time (days)</th>
                        <th>Pace (lec/day)</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody style={{ color: "#505156" }}>
                      {isLoading && <LoadingSpinnerForTable />}
                      <CourseRow courses={currentCourses} />
                    </tbody>
                  </table>
                </div>
                {showCreateCourseModal && <AddCourseModal setShowCreateCourseModal={setShowCreateCourseModal} />}
                {showEditCourseModal && (
                  <EditCourseModal
                    setShowEditCourseModal={setShowEditCourseModal}
                    courseAuthorInput={selectedCourse?.author}
                    courseNameInput={selectedCourse?.name}
                    lectureNoInput={selectedCourse?.totalLecNo}
                    courseId={selectedCourse?.id}
                    completionDays={selectedCourse?.completionDays}
                  />
                )}
                {showConfirmationPrompt && (
                  <ConfirmationPrompt
                    setShowConfirmationPrompt={setShowConfirmationPrompt}
                    status="danger"
                    message={deleteMessage}
                    isLoading={isDeleting}
                    confirmationAction={() => {
                      deleteCourseFn(selectedCourse?.id as number);
                    }}
                  />
                )}
                <div className="row">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const courseAction: ActionFunction = async ({ request, params }) => {
  const data = await request.formData();

  const mode = data.get("mode") as "editMode" | "createMode";

  let courseData = {
    name: data.get("courseName"),
    author: data.get("courseAuthor"),
    completionTime: Number(data.get("completionTime")),
    totalLecNo: Number(data.get("lectureTotal")),
  };
  if (mode === "editMode") {
    const id = { id: Number(data.get("courseId")) };
    courseData = { ...courseData, ...id };
  }

  try {
    let endpoint = mode === "editMode" ? "/update-course" : "/create-course";

    const url = ACD_DOMAIN + endpoint;
    const response = await axios.post(url, courseData, {
      headers: { Authorization: localStorage.getItem("accessToken") },
    });
    const resData = response.data;

    if (resData.error) {
      throw resData.error;
    }

    return redirect("/progress-admin/courses");
  } catch (err) {
    console.error(err);
    throw json({ message: "Failed to submit course form data" }, { status: 500 });
  }
};
