import { useEffect } from "react";
import "../assets/css/contact.css";
import { ContactUsBody } from "../components";

export const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact us";
  }, []);

  return (
    <>
      {/*--------- Error Messge sent from server should be renderered here ------------*/}

      <ContactUsBody />
    </>
  );
};

export default ContactUs;
