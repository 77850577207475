import { useEffect } from "react";
import "../assets/css/about.css";
import { FaqSection, FeaturesSection, IntroSection, ValuesSection } from "../components";

export const AboutUs = () => {
  useEffect(() => {
    document.title = "About us";
  }, []);

  return (
    <>
      <div className="section--container">
        {/* -------------  Intro Section  --------------- */}
        <IntroSection />

        {/* --------- --- Values Section --------------- */}
        <ValuesSection />

        {/* ------------- Features Section ---------------- */}
        <FeaturesSection />

        {/* ------------------  FAQ ------------------ */}
        <FaqSection />
        {/* ------------------ End FAQ ------------------ */}
      </div>
    </>
  );
};

export default AboutUs;
