import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { json, redirect } from "react-router-dom";
import { NotificationModal } from "../../components";
import { AcademySummary } from "../../components/admin-dashboard/academy-summary";
import { AdminUsersProgressTable } from "../../components/admin-dashboard/admin-users-progress-table";
import { ACD_DOMAIN } from "../../constants";
import { ActiveStudents } from "../../models";
import { RootState } from "../../store";
import { useGetActiveStudentsQuery } from "../../store/rtk/admin-api";
import { decodeToken, formatDate } from "../../util";

export const AdminDashboard = () => {
  const [members, setMembers] = useState<ActiveStudents[] | []>([]);
  const [memberNo, setMemberNo] = useState(0);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const { data, isLoading } = useGetActiveStudentsQuery(null);

  useEffect(() => {
    // console.log("In useEffect ......");
    if (data && Array.isArray(data)) {
      // console.log("Active Members : ", data);
      setMemberNo(data.length);
      setMembers(data);
    }
    if (!Array.isArray(data) && !isLoading) {
      setShowErrorBanner(true);
      setServerError("Unable to fetch members");
    }
  }, [data, isLoading]);

  useEffect(() => {
    document.title = "Admin Dashboard";
  }, []);

  const token = useSelector((state: RootState) => state.auth.accessToken) || localStorage.getItem("accessToken");
  const decodedToken = token ? decodeToken(token!)! : { fName: "", lName: "" };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <div className="d-flex flex-column p-3" id="content-wrapper">
        <div id="content">
          <div className="container-fluid">
            <div className="d-sm-flex justify-content-between align-items-center mb-4">
              {/* <h3 className="text-dark mb-0">Admin dashboard</h3> */}
            </div>

            <div className="row mb-1">
              <div className="col-12 col-xl-8 mb-4 mb-xl-0 ">
                <h3 className="font-weight-bold">Welcome {`${decodedToken.fName} ${decodedToken.lName}`}</h3>
              </div>
              <div className="col-12 col-xl-4">
                <div className="justify-content-end d-flex">
                  <div
                    className="dropdown flex-md-grow-1 flex-xl-grow-0 p-1"
                    style={{ borderRadius: 5, border: "1px solid" }}
                  >
                    <span className="mdi mdi-calendar"></span> Today ({formatDate(new Date())})
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/* --------------- Table to show each user's progress ------------- */}
              <div className="col-lg-8 grid-margin stretch-card tableCard">
                <AdminUsersProgressTable fetchedMembers={members} isLoading={isLoading} />
              </div>

              {/* ----------------------- Summary of academy organization --------------- */}
              <div className="col">
                <AcademySummary memberNo={memberNo} />
              </div>
            </div>
          </div>
        </div>

        {/* <footer className="bg-white sticky-footer">
          <div className="container my-auto">
            <div className="text-center my-auto copyright">
              <span>Copyright © TechRise Academy 2024</span>
            </div>
          </div>
        </footer> */}
      </div>
    </>
  );
};

export const adminDashboardLoader = async () => {
  try {
    const url = ACD_DOMAIN + "/get-role";
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return redirect("/login");
    }
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const resData = response.data;
    if (resData.error) {
      throw resData.error;
    }

    const transFormedRoles = Array.isArray(resData) ? resData.map(r => r.role) : [];

    if (transFormedRoles.includes("admin") || transFormedRoles.includes("superAdmin")) {
      return null;
    }

    throw json({ message: "Unauthorized access !" }, { status: 401 });
  } catch (err) {
    console.error(err);
    throw err;
  }
};
