import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ReactComponent as PersonSvg } from "../../assets/svg/person-4.svg";
import { useLazyGetRoleQuery } from "../../store/rtk/admin-api";
import { ErrorType, Role } from "../../models";
import { NotificationModal, ViewAdminsModal } from "../../components";
import { decodeToken, refreshTokenFunc } from "../../util";
import { PermissionModal } from "../../components/admin-dashboard/permission-modal";
import { authSliceActions } from "../../store/auth-slice";
import { OAUTH_DOMAIN } from "../../constants";
import { LoadingSpinner } from "../../components/common/loading-spinner";
import { RootState } from "../../store";

export const AdminProfile = () => {
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [serverResp, setServerResp] = useState<null | string>(null);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [showAdminsModals, setShowAdminsModals] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState<Role | null>(null);
  const [permissionType, setPermissionType] = useState<"grant" | "revoke" | "">("");
  const [getRole] = useLazyGetRoleQuery();
  const detailsUpdateHref = `${OAUTH_DOMAIN}`;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.accessToken) || localStorage.getItem("accessToken");
  const refreshToken =
    useSelector((state: RootState) => state.auth.refreshToken) || localStorage.getItem("refreshToken")!;

  useEffect(() => {
    if (!token) {
      dispatch(authSliceActions.logout());
      navigate("/login");
    }

    const fetchRole = async () => {
      const role = (await getRole(null)) as { data: { role: Role }[]; error: ErrorType };

      if (role.error) {
        setRole(null);
        return;
      }
      const transFormedRoles = Array.isArray(role.data) ? role.data.map(r => r.role) : [];
      if (transFormedRoles.includes("admin")) {
        setRole("admin");
      } else if (transFormedRoles.includes("superAdmin")) {
        setRole("superAdmin");
      }
    };

    fetchRole();
  }, [navigate, token, dispatch, getRole]);

  useEffect(() => {
    document.title = "Admin Profile";
  }, []);

  const decodedToken = token ? decodeToken(token!)! : { lName: "", email: "", imageUrl: "", fName: "", phoneNo: "" };
  const isSuperAdmin = Boolean(role) && role === "superAdmin";

  // Class selectors for the error states of each input
  const firstNameClasses = "form-control";
  const lastNameClasses = "form-control";
  const emailClasses = "form-control";
  const phoneNoClasses = "form-control";

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="container-fluid mt-5">
        <h3 className="text-dark mb-4">Profile</h3>
        <div className="row mb-3">
          <div className="col-lg-4">
            <div className="card mb-3">
              <div className="card-body text-center shadow" style={{ minHeight: "210px" }}>
                {decodedToken.imageUrl ? (
                  <img
                    className="rounded-circle mb-3 mt-3"
                    src={decodedToken.imageUrl}
                    alt="profile"
                    width="210"
                    height="210"
                    style={{ objectFit: "cover", objectPosition: "top" }}
                  />
                ) : (
                  <PersonSvg
                    className="rounded-circle mb-3 mt-4"
                    style={{ width: 210, height: 210, border: "1px solid #B9BABD", fill: "#B9BABD" }}
                  />
                )}
                <Box sx={{ display: "flex", px: 2, justifyContent: "space-between" }}>
                  <Button variant="outlined" size="small" href={detailsUpdateHref} target="_blank">
                    Edit user details
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<SyncRoundedIcon />}
                    data-tooltip-id="refresh"
                    data-tooltip-content="use this to sync any account updates from the oauth service to your profile"
                    onClick={() => {
                      refreshTokenFunc(refreshToken, authSliceActions, dispatch, navigate, setIsLoading);
                    }}
                  >
                    Refresh
                  </Button>
                </Box>
              </div>
            </div>
          </div>

          <div className="col-lg-8 ">
            <div className="row">
              <div className="card shadow mb-3">
                <div className="card-header py-3">
                  <p className="text-primary m-0 fw-bold">User Info</p>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="firstName">
                          <strong>First Name</strong>
                        </label>
                        <input
                          className={firstNameClasses}
                          type="text"
                          id="firstName"
                          name="firstName"
                          defaultValue={decodedToken.fName}
                          disabled={true}
                          aria-describedby="firstNameFeedback"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="lastName">
                          <strong>Last Name</strong>
                        </label>
                        <input
                          className={lastNameClasses}
                          type="text"
                          id="lastName"
                          name="lastName"
                          disabled={true}
                          defaultValue={decodedToken.lName}
                          aria-describedby="lastNameFeedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="phoneNo">
                          <strong>Phone No</strong>
                        </label>
                        <input
                          className={phoneNoClasses}
                          type="tel"
                          id="phoneNo"
                          defaultValue={decodedToken.phoneNo}
                          name="phoneNo"
                          disabled={true}
                          aria-describedby="phoneNoFeedback"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="email">
                          <strong>Email Address</strong>
                        </label>
                        <input
                          className={emailClasses}
                          type="email"
                          id="email"
                          defaultValue={decodedToken.email}
                          aria-describedby="emailFeedback"
                          name="email"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col text-center">
                      <button
                        className="btn btn-success text-white"
                        type="button"
                        disabled={!isSuperAdmin}
                        onClick={() => {
                          setShowPermissionModal(true);
                          setPermissionType("grant");
                        }}
                      >
                        Grant admin permission
                      </button>
                    </div>
                    <div className="col text-center">
                      <button
                        className="btn btn-danger"
                        type="button"
                        disabled={!isSuperAdmin}
                        onClick={() => {
                          setShowPermissionModal(true);
                          setPermissionType("revoke");
                        }}
                      >
                        Revoke admin permission
                      </button>
                    </div>
                    <div className="col text-center">
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled={!isSuperAdmin}
                        onClick={() => {
                          setShowAdminsModals(true);
                        }}
                      >
                        View all admins
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {serverResp && showSuccessBanner && (
        <NotificationModal
          onClose={() => {
            setShowSuccessBanner(false);
            setServerResp(null);
          }}
          message={serverResp as string}
          show={true}
          success={true}
        />
      )}

      {showAdminsModals && <ViewAdminsModal show={showAdminsModals} setShow={setShowAdminsModals} />}

      {showPermissionModal && (
        <PermissionModal
          permissionType={permissionType}
          setShowPermissionModal={setShowPermissionModal}
          setServerResp={setServerResp}
          setShowSuccessBanner={setShowSuccessBanner}
        />
      )}
      <ReactTooltip id="refresh" place="bottom" style={{ maxWidth: "22.5rem" }} />
    </>
  );
};
