import { useLayoutEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { useSelector } from "react-redux";

import { DOC_DOMAIN, getClientId } from "../../constants";
import { RootState } from "../../store";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// image/png, image/jpeg, image/gif

const initialFileState = {
  imageFilePath: "",
  isTouched: false,
  isUploaded: false,
  isValid: false,
  isReverted: false,
  error: "",
};

interface ImageFileUploaderProps {
  fetchFormState?: (imagePath: string, uploadState: boolean) => void;
}

export const ImageFileUploader = ({ fetchFormState }: ImageFileUploaderProps) => {
  const [imageFile, setImageFile] = useState(initialFileState);
  const [clientId, setClientId] = useState<string | null>(null);
  useLayoutEffect(() => {
    (async () => {
      const id = await getClientId();
      setClientId(id);
    })();
  }, []);
  const imageFileInputClasses =
    imageFile.isValid === false && imageFile.isTouched === true && imageFile.error !== ""
      ? "form-control pt-3 form-control-user is-invalid"
      : "form-control pt-3 form-control-user";

  const accessToken = useSelector((state: RootState) => state.auth.accessToken) || localStorage.getItem("accessToken");

  return (
    <div className={imageFileInputClasses}>
      <FilePond
        allowReorder={true}
        allowMultiple={false}
        credits={false}
        allowFileSizeValidation={true}
        maxFileSize={"5MB"}
        labelMaxFileSizeExceeded={"File is too large"}
        maxFiles={1}
        acceptedFileTypes={["image/png", "image/jpeg"]}
        checkValidity={true}
        name="upload"
        server={{
          url: `${DOC_DOMAIN}`,
          process: {
            url: "/s3/pub-upload",
            method: "POST",
            headers: { Authorization: `Bearer ${accessToken}`, "Client-Auth": clientId || "" },
          },
          revert: {
            url: "/s3/pub-delete",
            method: "DELETE",
            headers: { "Content-Type": "application/json", "Client-Auth": clientId || "" },
          },
        }}
        onprocessfilerevert={() => {
          if (fetchFormState) {
            fetchFormState("", false);
          }
          setImageFile({
            imageFilePath: "",
            isTouched: true,
            isValid: false,
            isUploaded: false,
            isReverted: true,
            error: "",
          });
        }}
        onprocessfile={(error, file) => {
          // Data sent from the server : file.serverId
          if (!error) {
            const serverResponse: {
              url: string;
              msg: string;
            } = JSON.parse(file.serverId);
            const filePathDigitalOcean = serverResponse.url;
            // console.log("filePathDigitalOcean: ", filePathDigitalOcean);
            // console.log("serverResponse: ", serverResponse);

            //  Send upload state and imageurl to parent component
            if (fetchFormState) {
              fetchFormState(filePathDigitalOcean, true);
            }
            setImageFile({
              imageFilePath: filePathDigitalOcean,
              isTouched: true,
              isValid: true,
              isUploaded: true,
              isReverted: false,
              error: "",
            });
          }
        }}
        labelIdle='<span class="filepond--label-action">Upload Your new image</span>'
      />
      {imageFile.error && (
        <label
          className="row mt-0 pt-0 mb-3 "
          style={{
            display: "block",
            color: "#dc3545",
            fontSize: "0.875em",
            marginTop: "0.1rem",
          }}
        >
          {imageFile.error}
        </label>
      )}
    </div>
  );
};
