import { useEffect, useState } from "react";
import "../assets/css/index.css";
import * as Components from "../components";
import { LoadingSpinner } from "../components/common/loading-spinner";

export const Home = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [serverData, setServerData] = useState<string | null>(null);
  const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);

  useEffect(() => {
    document.title = "Home";
  }, []);

  return (
    <>
      {serverData && (
        <Components.NotificationModal
          onClose={() => {
            setServerData(null);
          }}
          message={serverData as string}
          show={true}
          success={true}
        />
      )}

      {showSpinner && <LoadingSpinner />}
      {/*-------- Showcase ---------- */}
      <Components.ShowcaseSection setShowEnrollmentModal={setShowEnrollmentModal} />

      {/*------------ Boxes  ----------*/}
      <Components.InfoTilesSection />

      {/*------------ Learn Sections  ----------*/}
      <Components.LearnSection />

      {/*------------ Latest Highlights  ----------*/}
      <Components.LatestHighLights />

      {/*------------ FAQ ------------- */}
      <Components.FaqSection />

      {/*------------ Testimonials Section ------------- */}
      {/* <TestimonialsSection /> */}
      <Components.TestimonialsCarousel />

      {/*------------ Modal --------------- */}
      {showEnrollmentModal && (
        <Components.EnrollmentModal
          setShowSpinner={setShowSpinner}
          setServerData={setServerData}
          setShowEnrollmentModal={setShowEnrollmentModal}
          showEnrollmentModal={showEnrollmentModal}
        />
      )}
    </>
  );
};
