import { FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";

import * as Models from "../../models";
import { sendLog } from "../../util/send-log";

// function transformErrorResponse(baseQueryReturnValue: FetchBaseQueryError, meta: FetchBaseQueryMeta, arg: unknown) {
//   // Handle all error scenarios here and display some sensible message to the user.
//   //If error is something that requires user action, tell the user so.
//   //if error is something that requires developer action, send log to the log service
//   return baseQueryReturnValue;
// }

export function transformErrorResponse(error: FetchBaseQueryError, meta: FetchBaseQueryMeta) {
  let message = "An unknown error occurred";
  // const endpoint = meta.request.url.split("/").pop();
  // console.log(`|--------  ${endpoint}  ------------|`);
  // console.log("Errorhandler error : ", error);
  // console.log("Error status : ", error.status);
  // console.log("Error status type : ", typeof error.status);
  switch (error.status) {
    case "FETCH_ERROR":
      message = "Please check your internet connection and try again";
      break;
    case "PARSING_ERROR":
      message = "Invalid json Data provided or An error occurred during data transformation";
      break;
    case "TIMEOUT_ERROR":
      message = "Something went wrong, Please try again later";
      break;
    case 429:
      message =
        "Sorry, but it seems like you've made too many requests in a short period of time. Please wait a while before making additional requests";
      break;
    case "CUSTOM_ERROR":
      break;
    default:
      message = (error as Models.ErrorType)?.data?.error?.message; // server side errors
  }

  if (!(error as Models.ErrorType).data?.error?.isUserError) {
    sendLog((error as Models.ErrorType).data?.error?.message);
    message = "Something went wrong, Please try again later";
  } else if ((error as Models.ErrorType).data?.error?.logout) {
    // Code  that is executed when a logout dispatch is made
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("refreshTokenExp");
    localStorage.removeItem("codeVerifier");
  }

  return message;
}
