import { useEffect, useState } from "react";
import { A11y, Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { testimonialArr } from "../../data";
import { FetchedTestimonial, StoredTestimonial } from "../../models";
import { useGetTestimonialsQuery } from "../../store/rtk";
import { TestimonialCard } from "./sub-components/testimonial-card";
import { TestimonialModal } from "./sub-components/testimonial-modal";
// import { Navigation, Scrollbar } from "swiper/modules";

export function TestimonialsCarousel() {
  const [testimonials, setTestimonials] = useState<StoredTestimonial[] | FetchedTestimonial[]>(testimonialArr);
  const { data } = useGetTestimonialsQuery(null);
  const [showTestimonialModal, setShowTestimonialModal] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState<null | StoredTestimonial>(null);

  const hideModal = () => {
    setShowTestimonialModal(false);
    setSelectedTestimonial(null);
  };

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setTestimonials(prevState => [...prevState, ...data]);
    }
  }, [data]);

  return (
    <>
      <section
        id="instructors"
        data-aos="fade-up"
        className="p-5  bg-dark"
        // style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
      >
        <h2 className="text-center text-white">Testimonials</h2>
        <p className="lead text-center text-white mb-5">
          Great minds that have gone through the bootcamp have this to say...
        </p>
        <div className="align-items-center">
          <Swiper
            slidesPerView="auto"
            grabCursor={true}
            loop={true} // Ensure continuous looping
            freeMode={true} // Allows for free sliding
            autoplay={{
              delay: 2500,
              // disableOnInteraction: false, // Ensures autoplay doesn't stop after user interaction
            }}
            // pagination={{
            //   clickable: true,
            // }}
            navigation={true} // Adds navigation buttons for desktop users
            modules={[Pagination, FreeMode, Autoplay, Navigation, A11y]}
            className="mySwiper"
          >
            {testimonials.map((testimonial, index) => {
              return (
                <SwiperSlide
                  key={`${testimonial.fName} ${testimonial.lName}`}
                  className="col-md-6 col-lg-3  px-2"
                  style={{ backgroundColor: "transparent", width: "100%", marginRight: 0 }}
                >
                  <TestimonialCard
                    testimonial={testimonial}
                    key={index}
                    setSelectedTestimonial={setSelectedTestimonial}
                    setShowTestimonialModal={setShowTestimonialModal}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
      {showTestimonialModal && <TestimonialModal onClose={hideModal} selectedTestimonial={selectedTestimonial!} />}
    </>
  );
}
