import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  refreshTokenExp: localStorage.getItem("refreshTokenExp") || null,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login(_, action) {
      const tokenObj = action.payload;

      localStorage.removeItem("codeVerifier");
      localStorage.removeItem("accessToken");
      localStorage.setItem("accessToken", tokenObj.accessToken);
      localStorage.setItem("refreshToken", tokenObj.refreshToken);
      localStorage.setItem("refreshTokenExp", tokenObj.refreshTokenExp);
      return { ...tokenObj, isLoggedIn: true };
    },
    logout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("refreshTokenExp");
      localStorage.removeItem("codeVerifier");
      return { accessToken: null, refreshToken: null, isLoggedIn: false, refreshTokenExp: null };
    },
  },
});

export const authReducer = authSlice.reducer;
export const authSliceActions = authSlice.actions;
